import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import bannerImage from '../../assets/static/banner/banner1.jpg'
import PageBanner from '../../components/Banner/PageBanner'
import BreadcrumbBar from '../../components/BreadcrumbBar'
import ClinicCard from '../../components/Cards/ClinicCard'
import clinicInfoList from '../../components/Clinic/Info'
import { GridItem, GridLayout } from '../../components/Grid'
import Layout from '../../components/Layout'
import PageContent from '../../components/PageContent'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { H2SpecialTitle as _H2SpecialTitle } from '../../components/Titles/H2SpecialTitle'
import { space } from '../../constants/length'
import { LightBG } from '../../constants/newColor'

const BreadcrumbBarWrapper = styled.div`
  margin: ${space.l}px 0;
`

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`

const StyledPageContent = styled(PageContent)`
  margin-bottom: 84px;
`

const ContentWrapper = styled.div`
  padding: 0 ${space.s}px;
`

const AreaWrapper = styled.div`
  background-color: ${LightBG};
  padding: ${space.xl}px 0;
  margin: ${space.xl}px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const H2SpecialTitle = styled(_H2SpecialTitle)``

const Clinic = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()
  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'breadcrumb.clinic' }),
      path: '/clinic'
    }
  ]

  return (
    <Layout {...props}>
      <Seo
        title='日不落牙醫集團｜全台牙科診所列表：台北、新竹、台中、台南'
        metaDescription='我們是牙齒矯正、陶瓷貼片的美學專家，提供SOV舒服美教育中心、矯正專科（隱形牙套、戴蒙矯正）、全瓷牙冠、人工植牙、兒童牙科等，遍布台北、新竹、台中、台南，提供給您最專業舒適的醫療環境。'
      />
      <PageBanner imageUrl={bannerImage} title={formatMessage({ id: 'banner.clinic' })} subtitle='Clinic' />
      <StyledPageContent>
        <GridLayout templateColumns={['100%', '100%', 'repeat(12, 1fr)']}>
          <GridItem column={['auto', 'auto', '3 / span 8']}>
            <ContentWrapper>
              <BreadcrumbBarWrapper>
                <BreadcrumbBar routes={routes} />
              </BreadcrumbBarWrapper>
              <StyledContentTitle>{formatMessage({ id: 'pageTitle.clinic' })}</StyledContentTitle>
              {map(
                clinicArea => (
                  <AreaWrapper key={clinicArea.area}>
                    <H2SpecialTitle>{clinicArea.area}</H2SpecialTitle>
                    {clinicArea.clinics.map((clinic, index) => (
                      <>
                        <ClinicCard
                          key={clinic.title}
                          title={clinic.title}
                          name={clinic.name}
                          primaryColor={clinic.primaryColor}
                          logoImageUrl={clinic.logoImageUrl}
                          address={clinic.address}
                          index={index}
                          length={clinicArea.clinics.length - 1}
                          hassite={clinic.hassite}
                        />
                      </>
                    ))}
                  </AreaWrapper>
                ),
                clinicInfoList
              )}
            </ContentWrapper>
          </GridItem>
        </GridLayout>
      </StyledPageContent>
    </Layout>
  )
}

export default Clinic
