import styled, { css } from 'styled-components'

import StarImg from '../../assets/images/star.svg'
import { space } from '../../constants/length'
import { H2 } from '../../constants/newText'
import { textSize } from '../../constants/text'
import { viewport } from '../../constants/viewport'

type justifyContentType = 'left' | 'center' | 'right'
interface H2SpecialTitleProps {
  children: string
  justifyContent?: justifyContentType
}

const StarImgStyled = css`
  width: 20px;
  height: 20px;
  margin: ${space.s}px;
  background-image: url(${StarImg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
`

const H2SpecialTitle = styled.h2<H2SpecialTitleProps>`
  ${H2}
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: center;
  margin-bottom: ${space.l}px;

  &:after,
  :before {
    ${StarImgStyled}
    content: '';
    display: block;
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    font-size: ${textSize.h2Mobile}px;
  }
`

export { H2SpecialTitle }
